import {
  DiscoveryApi,
  FetchApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { TeamsInsightsApiBackend } from '../types';
import {
  GroupEntity,
  MeetingStatisticsResponse,
  UserEntity,
} from '../interfaces';
import { getGroupsUrl, getStatsUrl, getUsersUrl } from '../utils';

export class TeamsInsightsApiBackendClient implements TeamsInsightsApiBackend {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
    this.identityApi = options.identityApi;
  }

  private async handleResponse<T>(response: Response): Promise<T> {
    if (!response.ok) throw new Error(response.statusText);

    return await response.json();
  }

  async getIgnoredGroups(): Promise<string[]> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'teams-insights',
    )}/get-ignored-groups`;
    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
    });

    return await this.handleResponse<string[]>(response);
  }

  async queryTeamsInsights(
    ignoredUsers: string[],
    uploadDate?: string,
    users?: string[],
  ): Promise<MeetingStatisticsResponse[]> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'teams-insights',
    )}/get-stats`;
    const url = getStatsUrl(baseUrl, ignoredUsers, uploadDate, users);
    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
    });

    return await this.handleResponse<MeetingStatisticsResponse[]>(response);
  }

  async queryTeamsInsightsAvailableDates(): Promise<string[]> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'teams-insights',
    )}/get-available-dates`;
    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
    });

    return await this.handleResponse<string[]>(response);
  }

  async queryTeamsInsightsAllUsers(): Promise<string[]> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'teams-insights',
    )}/get-all-users-from-stats`;
    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
    });

    return await this.handleResponse<string[]>(response);
  }

  async getGroups(): Promise<GroupEntity[]> {
    const { token } = await this.identityApi.getCredentials();
    if (!token) throw new Error('Unauthorized, missing token');

    const groups =
      (
        await this.identityApi.getBackstageIdentity()
      ).ownershipEntityRefs.filter(item => item.split(':')[0] === 'group') ??
      [];
    const baseUrl = await this.discoveryApi.getBaseUrl('teams-insights');
    const url = getGroupsUrl(groups, baseUrl);

    const response = await this.fetchApi.fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    });

    return await this.handleResponse<GroupEntity[]>(response);
  }

  async getUsers(users: string[]): Promise<UserEntity[]> {
    const { token } = await this.identityApi.getCredentials();
    if (!token) throw new Error('Unauthorized, missing token');

    const baseUrl = await this.discoveryApi.getBaseUrl('teams-insights');
    const url = getUsersUrl(users, baseUrl);

    const response = await this.fetchApi.fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    });

    return await this.handleResponse<UserEntity[]>(response);
  }
}
