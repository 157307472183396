export const getGroupsUrl = (groups: string[], baseUrl: string): string => {
  const mapGroups = groups.map(item => item.split('/')[1]) ?? [];
  const filterUserGroups =
    mapGroups.length > 0 ? `groups=${mapGroups.toString()}` : '';

  return `${baseUrl}/get-groups?${filterUserGroups}`;
};

export const getUsersUrl = (users: string[], baseUrl: string): string => {
  const filterUsers = users.length > 0 ? `users=${users.toString()}` : '';

  return `${baseUrl}/get-users?${filterUsers}`;
};

export const getStatsUrl = (
  baseUrl: string,
  ignoredUsers: string[] = [],
  uploadDate: string = '',
  users: string[] = [],
) => {
  const filterIgnoredUsers =
    ignoredUsers.length > 0 ? `ignoredUsers=${ignoredUsers.toString()}` : '';
  const filterDate = uploadDate !== '' ? `&uploadDate=${uploadDate}` : '';
  const filterUsers = users.length > 0 ? `&users=${users.toString()}` : '';

  return `${baseUrl}?${filterIgnoredUsers}${filterDate}${filterUsers}`;
};
