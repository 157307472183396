import { createPermission } from '@backstage/plugin-permission-common';

export const TEAMS_INSIGHTS_RESOURCE_TYPE = 'management-teams';

export const teamsInsightsReadPermission = createPermission({
  name: 'teams.insights.read',
  attributes: { action: 'read' },
  resourceType: TEAMS_INSIGHTS_RESOURCE_TYPE,
});

export const teamsInsightsPermissions = [teamsInsightsReadPermission];
