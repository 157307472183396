import {
  createBaseThemeOptions,
  pageTheme as defaultPageThemes,
  createUnifiedTheme,
  palettes,
  PageTheme,
} from '@backstage/theme';
import { alpha } from '@material-ui/core/styles';
import { latoBold, latoRegular } from './fonts';

const pageThemesFontColorOverride: Record<string, PageTheme> = {};
Object.keys(defaultPageThemes).map(key => {
  pageThemesFontColorOverride[key] = {
    ...defaultPageThemes[key],
    fontColor: '#FFFFFF',
  };
});

export const fbcTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#0C2941',
        light: '#4C9AFF',
        dark: '#172B4D',
      },
      secondary: {
        main: '#075574',
      },
      navigation: {
        ...palettes.light.navigation,
        background: '#0C2941',
        indicator: '#4C9AFF',
        color: '#d5d6db',
        selectedColor: '#ffffff',
        navItem: {
          hoverBackground: 'rgba(116,118,121,0.6)',
        },
        submenu: {
          background: '#075574',
        },
      },
    },
    fontFamily: 'Lato',
  }),
  defaultPageTheme: 'home',
  pageTheme: pageThemesFontColorOverride,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [latoRegular, latoBold],
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          background: theme.palette.primary.main,
          backgroundImage: 'unset',
          boxShadow: 'unset',
          padding: '24px',
        }),
        title: ({ theme }) => ({
          color: theme.page.fontColor,
          fontWeight: 900,
        }),
        subtitle: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        }),
        type: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        }),
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          fontSize: '12px',
          textTransform: 'uppercase',
          padding: '16px',
        },
      },
    },
  },
});
