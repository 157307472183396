import React from 'react';

import {
  HomePageTopVisited,
  HomePageRecentlyVisited,
  HomePageStarredEntities,
  HeaderWorldClock,
} from '@backstage/plugin-home';
import { CLOCK_CONFIGS, TIME_FORMAT } from '../../constants';
import { Content, Page, Header } from '@backstage/core-components';
import { Grid } from '@material-ui/core';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { HomePageToolkitLinks } from './components';
import { FinopsToolkitLinks, MonitoringToolkitLinks } from './components/HomePageToolkitLinks';

export const HomePage = () => {
  return (
    <Page themeId="home">
      <Header title="Omnichannel Retail Backstage" pageTitleOverride="Home">
        <HeaderWorldClock
          clockConfigs={CLOCK_CONFIGS}
          customTimeFormat={TIME_FORMAT}
        />
      </Header>
      <Content>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <HomePageSearchBar />
          </Grid>
          <Grid item xs={12} md={6}>
            <HomePageTopVisited />
          </Grid>
          <Grid item xs={12} md={6}>
            <HomePageRecentlyVisited />
          </Grid>
          <Grid item xs={12} md={3}>
            <MonitoringToolkitLinks />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* <HomePageTopLinks /> */}
            <HomePageToolkitLinks />
          </Grid>
          <Grid item xs={12} md={3}>
            <FinopsToolkitLinks />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
