import React from 'react';
import { HomePageToolkit } from '@backstage/plugin-home';
import DatadogLogo from '../../../assets/images/datadog.svg';
import SonarqubeLogo from '../../../assets/images/sonarqube.png';
import GitlabLogo from '../../../assets/images/gitlab.png';
import ConfluenceLogo from '../../../assets/images/confluence.svg';
import ApiCurioLogo from '../../../assets/images/apicurio.png';
import GrafanaLogo from '../../../assets/images/grafana.png';
import JiraLogo from '../../../assets/images/jira.svg';
import EssentialLogo from '../../../assets/images/essential.png';
import LookerStudioLogo from '../../../assets/images/lookerstudio.svg';
import PowerBILogo from '../../../assets/images/powerbi.svg';
import ArgoCDLogo from '../../../assets/images/argocd.svg';
import KubecostLogo from '../../../assets/images/kubecost.png';
import KeycloakLogo from '../../../assets/images/keycloak.png'

const imageIcon = (alt: string, path: string) => (
  <img
    alt={alt}
    src={path}
    style={{
      width: '40px',
      height: 'auto',
    }}
  />
);

interface TopLink {
  url: string;
  label: string;
  icon: React.ReactNode;
}

const MONITORING_LINKS: TopLink[] = [
  {
    url: 'https://datadog.falabella.tech',
    label: 'Datadog',
    icon: imageIcon('Datadog', DatadogLogo),
  },
  {
    url: 'https://monitor.sodimac-it.com',
    label: 'Grafana',
    icon: imageIcon('Grafana', GrafanaLogo),
  },
  {
    url: 'https://lookerstudio.google.com/reporting/2e9bda04-6a24-42fb-96c4-3feeb60f2e5f/page/p_k5wwp5nd6c',
    label: 'Four Key Metrics',
    icon: imageIcon('LookerStudio', LookerStudioLogo),
  },
  {
    url: 'https://app.powerbi.com/home?experience=power-bi',
    label: 'PowerBI',
    icon: imageIcon('PowerBI', PowerBILogo),
  },
  {
    url: 'https://lookerstudio.google.com/u/1/navigation/reporting',
    label: 'Looker Studio',
    icon: imageIcon('LookerStudio', LookerStudioLogo),
  },
]

const TOP_LINKS: TopLink[] = [
  {
    url: 'https://sonarqube.falabella.tech',
    label: 'Sonarqube',
    icon: imageIcon('Sonarqube', SonarqubeLogo),
  },
  {
    url: 'https://gitlab.falabella.tech',
    label: 'Gitlab',
    icon: imageIcon('Gitlab', GitlabLogo),
  },
  {
    url: 'https://confluence.falabella.tech',
    label: 'Confluence',
    icon: imageIcon('Confluence', ConfluenceLogo),
  },
  {
    url: 'https://api-studio.falabella.tech',
    label: 'ApiCurio',
    icon: imageIcon('ApiCurio', ApiCurioLogo),
  },
  {
    url: 'https://jira.falabella.tech',
    label: 'Jira',
    icon: imageIcon('Jira', JiraLogo),
  },
  {
    url: 'https://confluence.falabella.tech/display/MDF/Generate+a+jira+incident+using+request+type+ticket',
    label: 'Devops Ticket',
    icon: imageIcon('TicketDevOps', JiraLogo),
  },
  {
    url: 'https://falabella.essentialintelligence.com/viewer/5dfb6493deb91668e39a_3/report?XML=reportXML.xml&XSL=user/core_bl_bus_platforms_dashboard.xsl&LABEL=Business%20Platform%20Dashboard&cl=en-gb',
    label: 'Portal Essential',
    icon: imageIcon('PortalEssential', EssentialLogo),
  },
  {
    url: 'https://argocd.merchandise.fbusinesscenter.com',
    label: 'ArgoCD Merch',
    icon: imageIcon('ArgoCD', ArgoCDLogo),
  },
  {
    url: 'https://qa-access-key-corp.falabella.tech/auth',
    label: 'CAMP Lower Env',
    icon: imageIcon('Keycloak', KeycloakLogo)
  },
  {
    url: 'https://access-key-corp.falabella.tech/auth',
    label: 'CAMP Production',
    icon: imageIcon('Keycloak', KeycloakLogo)
  }
];

const FINOPS_LINKS: TopLink[] = [
  {
    url: 'https://fbusinesscenter.com/foundational/',
    label: 'Kubecost Found',
    icon: imageIcon('Kubecost', KubecostLogo),
  },
  {
    url: 'https://fbusinesscenter.com/interconnect/',
    label: 'Kubecost Interconnect',
    icon: imageIcon('Kubecost', KubecostLogo),
  },
  {
    url: 'https://app.powerbi.com/Redirect?action=OpenReport&appId=301f3f16-efd1-480b-82e8-53f06bc0c5a4&reportObjectId=72d5b835-0fbe-445f-b4d6-f1dcf0a5263f&ctid=c4a8886b-f140-478b-ac47-249555c30afd&reportPage=ReportSectiond3379319aca140156469&pbi_source=appShareLink&portalSessionId=3791da84-4b2a-4d24-8e19-acc3523741e0',
    label: 'PowerBI Cloud Costs',
    icon: imageIcon('PowerBI', PowerBILogo),
  }
]

export const MonitoringToolkitLinks = () => {
  return <HomePageToolkit tools={MONITORING_LINKS} title='Monitoring' />;
}

export const HomePageToolkitLinks = () => {
  return <HomePageToolkit tools={TOP_LINKS} title='Development tools / Shortcuts' />;
};

export const FinopsToolkitLinks = () => {
  return <HomePageToolkit tools={FINOPS_LINKS} title='Finops' />;
}