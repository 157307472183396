import { createApiRef } from '@backstage/core-plugin-api';
import {
  CatalogApplicationOmnichannelRetailMapperResponse,
  IncidentObservability,
} from '../interfaces';

export const observabilityApiRefFrontend =
  createApiRef<ObservabilityApiBackend>({
    id: 'plugin.base-api-frontend.service',
  });

export interface ObservabilityApiBackend {
  queryCatalogApplicationsEssential(): Promise<
    CatalogApplicationOmnichannelRetailMapperResponse[]
  >;
  queryIncidentObservability(): Promise<IncidentObservability[]>;
}
