import { createApiRef } from '@backstage/core-plugin-api';
import {
  UserEntity,
  type GroupEntity,
  type MeetingStatisticsResponse,
} from '../interfaces';

export const teamsInsightsApiRefFrontend =
  createApiRef<TeamsInsightsApiBackend>({
    id: 'plugin.teams-insights-frontend.service',
  });

export interface TeamsInsightsApiBackend {
  queryTeamsInsights(
    ignoredUsers: string[],
    uploadDate?: string,
    users?: string[],
  ): Promise<MeetingStatisticsResponse[]>;
  queryTeamsInsightsAvailableDates(): Promise<string[]>;
  queryTeamsInsightsAllUsers(): Promise<string[]>;
  getIgnoredGroups(): Promise<string[]>;
  getGroups(): Promise<GroupEntity[]>;
  getUsers(users: string[]): Promise<UserEntity[]>;
}
