import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';

export interface TopLink {
  href: string;
  label: string;
  icon?: React.ReactNode;
}

export const HomePageTopLinks = (links: TopLink[]) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Shortcuts</Typography>
        <List component="nav">
          {links.map(({ href, label }) => (
            <ListItem key={label} component="a" href={href} target="_blank">
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};
