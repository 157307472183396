import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import { useApp } from '@backstage/core-plugin-api';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  SidebarSubmenu,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CardTravel from '@material-ui/icons/CardTravel';
import CategoryIcon from '@material-ui/icons/Category';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import Timelapse from '@material-ui/icons/Timelapse';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import {
  usePermission,
  RequirePermission,
} from '@backstage/plugin-permission-react';
import {
  TEAMS_INSIGHTS_RESOURCE_TYPE,
  teamsInsightsReadPermission,
} from '@internal/backstage-plugin-teams-insights-common';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const { loading: loadingPermission } = usePermission({
    permission: teamsInsightsReadPermission,
    resourceRef: TEAMS_INSIGHTS_RESOURCE_TYPE,
  });

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* Global nav, not org-specific */}
          {/* <SidebarItem icon={HomeIcon} to="catalog" text="Home" /> */}
          <SidebarItem icon={HomeIcon} to="/" text="Home" />
          <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog">
            <SidebarSubmenu title="Catalog">
              <SidebarSubmenuItem
                title="Domains"
                to="catalog?filters[kind]=domain"
                icon={useApp().getSystemIcon('kind:domain')}
              />
              <SidebarSubmenuItem
                title="Systems"
                to="catalog?filters[kind]=system"
                icon={useApp().getSystemIcon('kind:system')}
              />
              <SidebarSubmenuItem
                title="Components"
                to="catalog?filters[kind]=component"
                icon={useApp().getSystemIcon('kind:component')}
              />
              <SidebarSubmenuItem
                title="APIs"
                to="catalog?filters[kind]=api"
                icon={useApp().getSystemIcon('kind:api')}
              />
              <SidebarDivider
                style={{
                  background: '#d5d6db',
                }}
              />
              <SidebarSubmenuItem
                title="Resources"
                to="catalog?filters[kind]=resource"
                icon={useApp().getSystemIcon('kind:resource')}
              />
              <SidebarDivider
                style={{
                  background: '#d5d6db',
                }}
              />
              <SidebarSubmenuItem
                title="Groups"
                to="catalog?filters[kind]=group"
                icon={useApp().getSystemIcon('kind:group')}
              />
              <SidebarSubmenuItem
                title="Users"
                to="catalog?filters[kind]=user"
                icon={useApp().getSystemIcon('kind:user')}
              />
            </SidebarSubmenu>
          </SidebarItem>
          <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
          <SidebarItem icon={CardTravel} to="toolbox" text="Toolbox" />
          <SidebarItem
            icon={CreateComponentIcon}
            to="create"
            text="Create..."
          />
          {/* End global nav */}
          <SidebarDivider />
          <SidebarScrollWrapper>
            <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
            <SidebarItem
              icon={TrackChangesIcon}
              to="observability"
              text="Observability"
            />
            <SidebarItem icon={Timelapse} to="time-saver" text="Time Saver" />
            {!loadingPermission && (
              <RequirePermission
                permission={teamsInsightsReadPermission}
                resourceRef={TEAMS_INSIGHTS_RESOURCE_TYPE}
                errorPage={<></>}
              >
                <SidebarItem
                  icon={ShowChartIcon}
                  to="teams-insights"
                  text="Teams Insights"
                />
              </RequirePermission>
            )}
            <SidebarItem
              icon={InsertChartIcon}
              to="looker-studio"
              text="Looker Studio"
            />
          </SidebarScrollWrapper>
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
