import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ObservabilityApiBackend } from '../types';
import {
  CatalogApplicationOmnichannelRetailMapperResponse,
  IncidentObservability,
} from '../interfaces';

export class ObservabilityApiBackendClient implements ObservabilityApiBackend {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  private async handleResponse<T>(response: Response): Promise<T> {
    if (!response.ok) throw new Error(response.statusText);

    return await response.json();
  }

  async queryCatalogApplicationsEssential(): Promise<
    CatalogApplicationOmnichannelRetailMapperResponse[]
  > {
    const url = `${await this.discoveryApi.getBaseUrl(
      'observability',
    )}/catalog-applications-essential`;
    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
    });

    return await this.handleResponse<
      CatalogApplicationOmnichannelRetailMapperResponse[]
    >(response);
  }

  async queryIncidentObservability(): Promise<IncidentObservability[]> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'observability',
    )}/incident-observability`;
    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
    });

    return await this.handleResponse<IncidentObservability[]>(response);
  }
}
