import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { teamsInsightsApiRefFrontend } from './types';
import { TeamsInsightsApiBackendClient } from './api/TeamsInsightsApiBackendClient';

export const teamsInsightsPlugin = createPlugin({
  id: 'teams-insights',
  apis: [
    createApiFactory({
      api: teamsInsightsApiRefFrontend,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, fetchApi, identityApi }) =>
        new TeamsInsightsApiBackendClient({
          discoveryApi,
          fetchApi,
          identityApi,
        }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const TeamsInsightsPage = teamsInsightsPlugin.provide(
  createRoutableExtension({
    name: 'TeamsInsightsPage',
    component: () =>
      import('./components/index').then(m => m.TeamsInsightsPage),
    mountPoint: rootRouteRef,
  }),
);
