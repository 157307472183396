import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { observabilityApiRefFrontend } from './types';
import { ObservabilityApiBackendClient } from './api/ObservabilityApiBackendClient';

export const observabilityPlugin = createPlugin({
  id: 'observability',
  apis: [
    createApiFactory({
      api: observabilityApiRefFrontend,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new ObservabilityApiBackendClient({ discoveryApi, fetchApi }),
    }),
  ],

  routes: {
    root: rootRouteRef,
  },
});

export const ObservabilityPage = observabilityPlugin.provide(
  createRoutableExtension({
    name: 'ObservabilityPage',
    component: () => import('./components/index').then(m => m.Main),
    mountPoint: rootRouteRef,
  }),
);
