import LatoRegular from '../../assets/fonts/Lato/Lato-Regular.woff2';
import LatoBold from '../../assets/fonts/Lato/Lato-Bold.woff2';

const latoRegular = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Lato'),
    url(${LatoRegular}) format('woff'),
  `,
};

const latoBold = {
  fontFamily: 'Lato-Bold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Lato-Bold'),
    url(${LatoBold}) format('woff'),
  `,
};

export { latoRegular, latoBold };
