import { ClockConfig } from '@backstage/plugin-home';

const CLOCK_CONFIGS: ClockConfig[] = [
  {
    label: 'CL',
    timeZone: 'America/Santiago',
  },
  {
    label: 'PE',
    timeZone: 'America/Lima',
  },
  {
    label: 'AR',
    timeZone: 'America/Argentina/Buenos_Aires',
  },
  {
    label: 'BR',
    timeZone: 'America/Sao_Paulo',
  },
  {
    label: 'MX',
    timeZone: 'America/Mexico_City',
  },
  {
    label: 'IN',
    timeZone: 'Asia/Kolkata',
  },
];

const TIME_FORMAT: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hourCycle: 'h24',
};

export { CLOCK_CONFIGS, TIME_FORMAT };
