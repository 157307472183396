import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const lookerStudioPlugin = createPlugin({
  id: 'looker-studio',
  routes: {
    root: rootRouteRef,
  },
});

export const LookerStudioPage = lookerStudioPlugin.provide(
  createRoutableExtension({
    name: 'LookerStudioPage',
    component: () => import('./components/index').then(m => m.Main),
    mountPoint: rootRouteRef,
  }),
);
